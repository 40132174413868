import { i18nTranslate } from "@/plugins/i18n";
import { CustomRadioOption } from "@/shared/components/CustomRadioOptions.vue";
import { config } from "@/shared/utils/config";
import { CheckPromoCode_checkPromoCode_PromoCode } from "@/api/quotations/__generated__/CheckPromoCode";
import { LatestQuotation_latestQuotation_Quotation_promoCode } from "@/api/quotations/__generated__/LatestQuotation";

export const generalTermsList = [
  i18nTranslate(
    "<b>Premium Model Creator Refund:</b> If you purchased the Premium Model Creator but no Premium Model Creators are available, you agree to receive a refund."
  ),
  i18nTranslate(
    "<b>Bank Fees:</b> You agree to take care of any transaction fee/ forex fee that your bank might charge you in paying for {merchantBrand}",
    { merchantBrand: config.merchantBrand }
  ),
  i18nTranslate(
    "<b>Hiring:</b> You agree NOT to hire your location visit model creator outside of {merchantBrand} within the next 6 month.",
    { merchantBrand: config.merchantBrandShortName }
  ),
  i18nTranslate(
    "<b>Change of order:</b> You agree to not change your video order on the day of the shooting."
  ),
];

export const testimonialTermsList: string[] = [
  i18nTranslate(
    "<b>Video Delivery:</b> You agree that {merchantBrand} will deliver your videos ALL AT ONCE every month.",
    { merchantBrand: config.merchantBrand }
  ),
  i18nTranslate(
    "<b>Video Credit:</b> You agree that you will order the videos in a timely manner to ensure that all the video credit for that month will be consumed within 40 days otherwise it’s considered consumed."
  ),
  i18nTranslate(
    "<b>Lock-in Period:</b> You agree to finish the agreed upon period. If, for any reason, the client wants to terminate earlier, the payment is non-refundable."
  ),
];

export const testimonialTermsFullList: string[] = [
  i18nTranslate(
    "<b>Location visit:</b> You agree not to demand the model creator to change the video ordered prior to their visit."
  ),
  i18nTranslate(
    "<b>Feedback:</b> You agree to 1) answer a feedback form, 2) commit to zoom feedback call."
  ),
  i18nTranslate(
    "<b>Logo and Video Use:</b> You agree that we can use your videos and logos in our marketing materials and social media.",
    { merchantBrand: config.merchantBrand }
  ),
  i18nTranslate(
    "<b>Shipping:</b> You agree to ship out your products ALL AT ONCE to the chosen model creator/s."
  ),
  i18nTranslate(
    "<b>Video Delivery:</b> You agree that {merchantBrand} will deliver your videos ALL AT ONCE every month.",
    { merchantBrand: config.merchantBrand }
  ),
  i18nTranslate(
    "<b>Video Credit:</b> You agree that you will order the videos in a timely manner to ensure that all the video credit for that month will be consumed within 40 days otherwise it’s considered consumed."
  ),
  i18nTranslate(
    "<b>Subscription Payment:</b> Your payment will recur in the exact same day of your 1st subscription (e.g. subscribed on April 30, next month will be May 30). You agree that you will finish the project before the next month subscription recurs and you will be diligent in the payment every following months."
  ),
  i18nTranslate(
    "<b>Video Credit Expiration:</b> You agree to finish consuming all your video credits until the end of your subscription."
  ),
  i18nTranslate(
    "<b>Lock-in Period:</b> You agree to finish the agreed upon period. If, for any reason, the client wants to terminate earlier, the payment is non-refundable."
  ),
];

export const testimonialTermsOptions: CustomRadioOption[] =
  testimonialTermsList.map((item, index) => ({
    value: index.toString(),
    labelDescription: item,
  }));

export const getPromoCodeTerms = (
  promo?:
    | CheckPromoCode_checkPromoCode_PromoCode
    | LatestQuotation_latestQuotation_Quotation_promoCode
    | null
): string[] => {
  const terms = [
    i18nTranslate("Video length is short only."),
    i18nTranslate("Delivery of videos is in 10 days."),
  ];

  if (promo?.durationInMonths) {
    terms.push(
      i18nTranslate(
        "The minimum subscription period is {n} months.",
        promo.durationInMonths
      )
    );
  }

  terms.push(
    i18nTranslate(
      "This promo code will only apply for 1 month. In the succeeding months, the original price will apply and you can purchase Long Video and Express add-ons."
    )
  );

  return terms;
};
